import 'magnific-popup';

jQuery( document ).ready(function($) {
    $('.mfp-inline').magnificPopup({}); 

    let url=window.location.href;
    var index = url.indexOf("#");
    if (index !== -1)
    {
        var hash = url.substring(index + 1);
        if (hash == "join"){
            $.magnificPopup.open({
                items: {
                  src: '#signup-popup',
                  type: 'inline'
                }
              });
        }
    } 
});